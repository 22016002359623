<script setup>
import { ref } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import SJAMatrix from 'sja.vue.libraries.components.SJAMatrix.vue'
import MobileSJAMatrix from 'sja.vue.libraries.mob.MobileSJAMatrix.vue'
import { modalFinalMatrix, procGetMatrix } from 'sja.vue.libraries.modules.utilities.js'
import { isMobile } from 'o365.GlobalState.ts';

let dsProbabilitiesLkp = getDataObjectById(`dsProbabilitiesLkp`)
let dsConsequencesLkp = getDataObjectById(`dsConsequencesLkp`)
let dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`)

let refFinalProbabilityValue = ref(null);
let refFinalConsequenceValue = ref(null);
let refFinalProbability_ID = ref(null);
let refFinalConsequence_ID = ref(null);

async function updateMatrix() {
    let result;
    try {
        result = await procGetMatrix.execute({
            ProbabilityValue: refFinalProbabilityValue.value ? refFinalProbabilityValue.value : dsHazardsModalFinal.current.FinalProbabilityValue,
            ConsequenceValue: refFinalConsequenceValue.value ? refFinalConsequenceValue.value : dsHazardsModalFinal.current.FinalConsequenceValue,
            Probability_ID: refFinalProbability_ID.value ? refFinalProbability_ID.value : dsHazardsModalFinal.current.FinalProbabilityValueID,
            Consequence_ID: refFinalConsequence_ID.value ? refFinalConsequence_ID.value : dsHazardsModalFinal.current.FinalConsequenceValueID
        });

        dsHazardsModalFinal.current.FinalMatrix_ID = result.Table[0].ID

    } catch (error) {
        console.log(error);
    }
}

async function saveMatrix() {
    let dsTasksHazards = getDataObjectById(`dsTasksHazards_${dsHazardsModalFinal.current.SJA_ID}`);
    await dsHazardsModalFinal.save().then(() => dsTasksHazards.load())
    dsHazardsModalFinal.load()
}

function setRefs(sel, refType) {
    if (refType == "FC") {
        refFinalConsequenceValue.value = sel.Value;
        refFinalConsequence_ID.value = sel.ID;
    }
    if (refType === "FP") {
        refFinalProbabilityValue.value = sel.Value;
        refFinalProbability_ID.value = sel.ID;
    }
    updateMatrix()
}

dsHazardsModalFinal.on("FieldChanged", () => {
    if ((dsHazardsModalFinal.current.FinalConsequenceNameAndDesc != null && dsHazardsModalFinal.current.FinalProbabilityNameAndDesc != null) && dsHazardsModalFinal.current._state.hasChanges) {
        document.querySelector('.save-btn-final').removeAttribute('disabled')
        document.querySelector('.undo-btn-final').removeAttribute('disabled')
    } else {
        document.querySelector('.save-btn-final').setAttribute('disabled', 'disabled')
        document.querySelector('.undo-btn-final').setAttribute('disabled', 'disabled')
    }
});

dsHazardsModalFinal.on('ChangesCancelled', () => {
    refFinalProbabilityValue.value = null;
    refFinalConsequenceValue.value = null;
    refFinalProbability_ID.value = null;
    refFinalConsequence_ID.value = null;
    document.querySelector('.save-btn-final').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-final').setAttribute('disabled', 'disabled')

});

dsHazardsModalFinal.on("AfterSave", async function () {
    refFinalProbabilityValue.value = null;
    refFinalConsequenceValue.value = null;
    refFinalProbability_ID.value = null;
    refFinalConsequence_ID.value = null;
    document.querySelector('.save-btn-final').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-final').setAttribute('disabled', 'disabled')
});

function onModalHidden() {
    document.querySelector('.save-btn-final').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-final').setAttribute('disabled', 'disabled')
}

</script>

<template>
    <OModal ref="modalFinalMatrix" @hidden="onModalHidden()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Final Risk Assessment') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="dsHazardsModalFinal.cancelChanges()"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="d-flex flex-column flex-fill m-2 justify-content-center">
                            <h6>{{ $t('Consequence') }}*</h6>
                            <ODataLookup class="form-control my-3" :data-object="dsConsequencesLkp" :disableRecent="true"
                                v-model="dsHazardsModalFinal.current.FinalConsequenceNameAndDesc"
                                :bind="sel => { dsHazardsModalFinal.current.FinalConsequenceNameAndDesc = sel.ValueAndNameAndDescription; setRefs(sel, 'FC') }">
                                <OColumn :headerName="$t('Value')" field="Value" width="60"></OColumn>
                                <OColumn :headerName="$t('Description')" field="NameAndDescription" width="350"></OColumn>
                            </ODataLookup>
                            <h6>{{ $t('Probability') }}*</h6>
                            <ODataLookup class="form-control my-3" :data-object="dsProbabilitiesLkp" :disableRecent="true"
                                v-model="dsHazardsModalFinal.current.FinalProbabilityNameAndDesc"
                                :bind="sel => { dsHazardsModalFinal.current.FinalProbabilityNameAndDesc = sel.ValueAndNameAndDescription; setRefs(sel, 'FP') }">
                                <OColumn :headerName="$t('Value')" field="Value" width="60"></OColumn>
                                <OColumn :headerName="$t('Description')" field="NameAndDescription" width="350"></OColumn>
                            </ODataLookup>
                        </div>

                        <div class="d-flex flex-column flex-fill align-items-center">
                            <h6>{{ $t('Matrix') }}</h6>
                            <MobileSJAMatrix v-if="isMobile" :hazardRow="dsHazardsModalFinal.data" matrixType="Final" class="m-2" />
                            <SJAMatrix v-else :hazardRow="dsHazardsModalFinal.data" matrixType="Final" class="m-2" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button disabled type="button" class="btn btn-primary save-btn-final" @click="saveMatrix()">{{
                        $t('Save') }}</button>
                    <button disabled type="button" class="btn btn-secondary undo-btn-final" @click="dsHazardsModalFinal.cancelChanges()">{{
                        $t('Undo') }}</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close"
                        @click="saveMatrix()">{{ $t('Save & Close') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>